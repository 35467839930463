.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 3px solid #958006 !important;
  /*border-top: 2px solid #958006 !important;*/
}

.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #958006 !important;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 1rem;
}

.ant-card-meta-title {
  color: #958006 !important;
}

.ant-card-meta-description {
  color: #958006 !important;
}
/*
.ant-menu-item-selected {
  background-color: #b039cc !important;
}*/
